<template>
  <div class="big_wrap_box">
    <!-- <van-nav-bar
      class="backBox"
      title="登录"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    /> -->
    <div class="content_box">
      <main>
        <div class="logo">
          <img src="../../assets/img/LoginBakImg@2x.png" alt="" />
        </div>
        <van-button class="confirmlBtn" type="primary" @click="login">微信用户快速登录</van-button>
        <footer>
          <van-checkbox v-model="privacy_policy_checked"></van-checkbox>
          <div class="privacy_policy_box">
            已阅读并同意
            <span class="privacy_policy" @click="view_policy"
              >《法律条款与隐私政策》</span
            >
          </div>
        </footer>
      </main>
    </div>
    <!-- 隐私弹窗 -->
    <privacy-policy
      :show="wang_show_privacy_policy"
      @get-cancel="getPolicyCancel"
      @get-success="getPolicySuccess"
      @get-polocy-show="getPolicyShow"
    ></privacy-policy>
  </div>
</template>
<script>
import axios from 'axios'
// import * as api from '../../api/api'
import * as common from '../../api/common'
import * as constant from '../../api/constant'
import { Toast } from 'vant'
import PrivacyPolicy from '../../components/PrivacyPolicy.vue'
import { mapState } from 'vuex'
export default {
  components: {
    PrivacyPolicy
  },
  data () {
    return {
      privacy_policy_checked: false,
      wang_show_privacy_policy: false,
      wxZfbInfo: {}
    }
  },
  mounted () {
    // todo  能来到Login 则说明尚未授权
    // ToDo 目前只能微信授权登录。支付宝只能走正常支付流程
    // const testUrl='http://192.168.0.165:8080/#/user/home?sid=yuen01&channel_path=-1'
    const code = this.getUrlParam('code')
    const historyCode = this.code
    // const historyCode = sessionStorage.getItem(common.sessionStorageKey.saveBillAuthCode)
    console.log('new code', code, 'old code', historyCode)
    if (code && historyCode !== code) {
    // if (historyCode === code) {
      this.$store.commit(constant.keyCodeVuex, code)
      this.getOpenid(code)
    } else {
      // this.wxZfbInfo = this.wxZfbAuthInfo
    }

    console.log('wxZfbAuthInfo', this.wxZfbAuthInfo)
  },
  computed: {
    ...mapState({
      code: state => state.code.code,
      historyCode: state => state.historyCode.historyCode,
      sid: state => state.sid.sid,
      wxZfbAuthInfo: state => state.wxZfbAuthInfo.wxZfbAuthInfo,
      channelPath: state => state.channelPath.channelPath
    })

  },
  methods: {
    getUrlParam (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') // 构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg) // 匹配目标参数
      if (r != null) {
        return decodeURIComponent(r[2])
      }
      return null // 返回参数值
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    login () {
      if (!this.privacy_policy_checked) {
        Toast.fail('请先同意隐私政策')
        return
      }
      console.log('点击登录按钮')
      // getAppId
      this.getAppId()

      // 登录成功后重定向到之前要访问的页面或默认页面
      // const redirect = this.$route.query.redirect || '/'
      // this.$router.replace(redirect)
    },

    view_policy () {
      console.log('查看隐私政策')
      this.wang_show_privacy_policy = true
    },
    getPolicyCancel (data) {
      this.wang_show_privacy_policy = false
      this.privacy_policy_checked = false
    },
    getPolicySuccess (data) {
      this.wang_show_privacy_policy = false
      this.privacy_policy_checked = true
    },
    getPolicyShow () {
      this.wang_show_privacy_policy = false
    },
    async getOpenid (code) {
      try {
        const { data } = await axios({
          url: common.cloudPayUrl + '/pub/pay.bill.park_plate.get_gzh_openid',
          method: 'POST',
          data: {
            sid: this.sid,
            code: code
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        console.log('openid res', data)
        // data = {
        //   api: 'pay.bill.park_plate.get_gzh_openid',
        //   city: '',
        //   code: 10000,
        //   country: '',
        //   headimgurl: 'https://thirdwx.qlogo.cn/mmopen/vi_32/bns8cBNzzFZn7FSWRIpV3N6V0fSuBSSnclHaJBFqjPL5GCZVghbB52qJoicMHQibDNxmFvyEzD8InSeBcwwBtJwDXNyp5YGGA1rO6sn8A22cc/132',
        //   language: '',
        //   msg: 'Success',
        //   nickname: '子规啼🧸ིྀ',
        //   openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w',
        //   privilege: [],
        //   province: '',
        //   sex: 0,
        //   unionid: 'o7pTwt11w1zVCx5bNmWdHgQPQYm0'
        // }
        if (data.code === 10000) {
          // this.wxZfbInfo = data
          this.$store.commit(constant.keyWxZfbAuthInfoVuex, data)
          this.$store.commit(constant.keyOpenIDVuex, data.openid)
          const redirect = this.$route.query.redirect || '/'
          console.log('AAA--redirect', redirect)
          this.$router.replace(redirect)
        } else {
          Toast.fail('登录出错')
        }
      } catch (e) {
        this.$toast('登录失败')
      }
    },
    async getAppId () {
      // http://192.168.0.165:8080/#/login?sid=testD&channel_path=-1
      // const theSid = 'testD'
      // const theChannelPath = -1
      // const host = ''
      try {
        const { data } = await axios({
          url: common.cloudPayUrl + '/pub/pay.bill.park_plate.get_gzh_appid',
          method: 'POST',
          data: {
            // sid: this.$Storejs.getItem('sid')
            sid: this.sid
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        console.log('appid res', data)
        if (data.code === 10000) {
          //  '?sid=' + this.$route.query.sid +
          // '&channel_path=' + this.$route.query.channel_path
          if (data.appid) {
            // let roleUrl=''

            // const redirectUrl = 'https://paycloud.test.szzcloud.club' + '/#' + this.$route.path +
            const redirectUrl = location.origin + '/#' + '/login' +
            // const redirectUrl = 'http:192.168.0.165:8080' + '/#' + this.$route.path +
              '?sid=' + this.sid +
              '&channel_path=' + this.channelPath
            const url = 'https://open.weixin.qq.com/connect/oauth2/authorize' +
              '?appid=' + data.appid +
              '&redirect_uri=' + encodeURIComponent(redirectUrl) +
              '&response_type=code' +
              '&scope=snsapi_userinfo' +
              '&state=' + this.sid +
              '#wechat_redirect'
            console.log('url,', url)
            window.location.href = url
          } else {
            this.$toast('获取appid失败')
          }
        }
      } catch (e) {

      }
    }
  }
}
</script>
<style lang="scss" scoped>
.big_wrap_box {
  background-color: #f8f7fc;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content_box {
  flex: 1;
  padding: 0.16rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .confirmlBtn{
    width: 3rem;
    height: 0.4rem;
  }
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.logo{
  margin-bottom: .32rem;
}
.logo img {
  max-width: 100%;
  height: auto;
  margin-top: 0.48rem;
}

footer {
  margin-top: 0.32rem;
  text-align: center;
  background-color: #fff;
  display: flex;
  .privacy_policy_box {
    margin-left: 0.08rem;
    font-size: 0.14rem;
    .privacy_policy {
      color: #007bff;
    }
  }
}
</style>
